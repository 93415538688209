
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../store/api';

export const photosApi = createApi({
  reducerPath: 'photosApi',
  baseQuery: baseQuery('/photo'),
  endpoints: (builder) => ({
    getPhoto: builder.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return `/?${queryString}`;
      },
      invalidatesTags: (_) => ["selectedAlbums"],
    }),
  }),
});

export const { useGetPhotoQuery, usePrefetch } = photosApi;
