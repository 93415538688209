import { configureStore } from '@reduxjs/toolkit';
import { albumsApi } from '../pages/albums/AlbumsApi';
import { loginApi } from '../pages/login/LoginApi';
import { photosApi } from '../pages/albums/PhotosApi';

const apis = [
  albumsApi,
  photosApi,
  loginApi,
];

const defaultReducers = {
};

const reducer = apis.reduce((newReducer, api) => {
  newReducer[api.reducerPath] = api.reducer;
  return newReducer;
}, defaultReducers);

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apis.map(api => api.middleware)),
});
