
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRedirect } from '../../store/api';

export const albumsApi = createApi({
  reducerPath: 'albumsApi',
  baseQuery: baseQueryWithRedirect('/albums'),
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => '/',
    }),
    getSelected: builder.query({
      query: () => '/selected',
      providesTags: ["selectedAlbums"],
    }),
    saveAlbums: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/save',
        body,
      }),
      invalidatesTags: ["selectedAlbums"],
    }),
  }),
});

export const { useGetAllQuery, useSaveAlbumsMutation, useGetSelectedQuery } = albumsApi;
