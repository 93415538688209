import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE = window.location.hostname === 'localhost' ? 'http://localhost:4000' : '/';

export const baseQuery = (path) => {
  return fetchBaseQuery({
    baseUrl: `${BASE}/api${path}`,
    mode: 'cors',
    credentials: 'include',
  });
};

export const baseQueryWithRedirect = (path) => {
  const base = baseQuery(path);
  return async (args, api, extraOptions) => {
    const result = await base(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      api.dispatch({
        payload: ['user'],
        type: "loginApi/invalidateTags",
      });
    }
    return result;
  };
};
