import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import loadable from '@loadable/component';
import Header from './components/layout/Header';
import SplashScreen from './pages/login/LoginChecker';
import PrivateRoute from './utils/PrivateRoute';

const AlbumsPage = loadable(() => import('./pages/albums/AlbumsPage'));
const LoginPage = loadable(() => import('./pages/login/LoginPage'));
const ComparePage = loadable(() => import('./pages/compare/ComparePage'));

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/login" element={<LoginPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/albums" element={<AlbumsPage />} />
          </Route>
          <Route path="/compare" element={<ComparePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
