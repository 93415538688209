
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../store/api';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: baseQuery('/user'),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => '/',
      providesTags: ["user"],
    }),
    logout: builder.mutation({
      query: () => ({
        method: 'POST',
        url: '/logout',
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const { useGetUserQuery, useLogoutMutation } = loginApi;
