import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useGetUserQuery } from '../pages/login/LoginApi';

export default function PrivateRoute() {
  const navigate = useNavigate();
  const { data: user = {}, isError, isSuccess } = useGetUserQuery();
  const isValidUser = isSuccess && user.email;

  React.useEffect(() => {
    if (isValidUser) {
      navigate('/albums');
    } else if (isError) {
      navigate('/login');
    }
  }, [isValidUser, isError, navigate]);

  return (
    <Outlet />
  );
}
